import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import Footer from '../templates/Footer';

import '../assets/scss/SquadDigital.scss'

import Main from '../templates/Main';
import MainSocialVert from "../components/MainSocialVert";

function SquadDigital() {

    const pagination = {
        clickable: true,
    };

    return (
    <>
        <Main /> 

        <div id="squadDigital">
 
            <header className="container-fluid">
                <div className="row">
                
                    <div className="col-2">
                        <MainSocialVert />
                    </div>
                        
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <div className="content">
                            <h2>Transformando <br /> Desafios em <br /> Resultados <br /> <strong>Digitais</strong> Há 17 <br /> Anos!</h2>
                            <Link to="#main-footer" className="first button gradient">Impulsionar o crescimento</Link>
                        </div>

                        <div className="carouselPartnerMobile">
                            <Swiper 
                                modules={[Pagination]} 
                                className="mySwiper"
                                freeMode={true}
                                centeredSlides={true}
                                slidesPerView={2}
                                loop={true}
                                watchSlidesProgress={true}
                                allowTouchMove={true}
                                pagination={pagination}
                            >
                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/images/partners/partner-meta.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/partners/partner-tiktok.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/partners/partner-google.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/partners/partner-microsoft.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/partners/partner-magento.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/partners/partner-wakeed.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/partners/partner-rdstation.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                            
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 bg"></div>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <p className="first">
                            Nós acreditamos que a <br /> Performance Digital 
                            pode <br /> elevar qualquer negócio a um <br /> novo patamar! 
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <p>
                            Na SATO7, somos apaixonados por resultados e <br /> somos considerados 
                            um dos líderes no cenário do <br /> marketing digital há 17 anos. Com o nosso 
                            Time <br /> GROWTH MARKETING, composto por especialistas em <br /> diversas áreas, 
                            estamos aqui para impulsionar o seu <br /> sucesso e transformar sua marca 
                            em uma referência <br /> no universo digital.
                        </p>
                    </div>
                </div>

            </header>  

            <section className="container-fluid" id="section1">
                <div className="row">
                    <div className="col-12">
                    
                        <h3>Nossos <strong>Serviços</strong></h3>
                      
                    </div>
                </div>
                <div className="row">

                    <div className="col-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Transformação Digital
                            </h4>
                            <p>
                                Estratégias inovadoras para posicionar sua <br /> marca 
                                no cenário digital em constante <br /> mudança.
                            </p>
                        </div>
                    </div>

                    <div className="col-4 mt-100">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Jornada do Consumidor
                            </h4>
                            <p>
                                Compreensão profunda do <br /> comportamento do consumidor, <br /> permitindo 
                                conexões significativas em <br />      todas as etapas da jornada.
                            </p>
                        </div>
                    </div>

                    <div className="col-4">      
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Digital Trade Marketing
                            </h4>
                            <p>
                                Impulsiona vendas através de parcerias <br /> comerciais sólidas e 
                                estratégias avançadas <br /> de marketing digital.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                    
                        <div className="carouselServiceMobile">
                            <Swiper 
                                modules={[Pagination]} 
                                className="mySwiper"
                                freeMode={true}
                                centeredSlides={true}
                                slidesPerView={2}
                                spaceBetween={10}
                                loop={true}
                                watchSlidesProgress={true}
                                allowTouchMove={true}
                                pagination={pagination}
                            >
                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Growth Marketing</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Transformação Digital</h2>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Jornada do Consumidor</h2>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Digital Trade Marketing</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Consultoria de Mídia</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Advanced Analytics</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Mídia Programática</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Social Media</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>Inbound Marketing</h2>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <i className="icon"></i>
                                        <h2>ADS em todas as plataformas</h2>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            
                        </div>


                    </div>
                </div>

                <div className="row mt-4">

                    <div className="col-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Consultoria de Mídia
                            </h4>
                            <p>
                                Otimiza a veiculação de anúncios para <br /> alcançar o 
                                público-alvo certo, no momento <br /> certo.
                            </p>
                        </div>
                    </div>

                    <div className="col-4 mt-100"> 
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Advanced Analytics
                            </h4>
                            <p>
                                Análise de dados avançada para obter <br /> insights 
                                valiosos e tomar decisões <br /> inteligentes.
                            </p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Mídia Programática
                            </h4>
                            <p>
                                Publicidade automatizada e altamente <br /> segmentada 
                                para maximizar o impacto das <br /> campanhas.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="row mt-4">

                    <div className="col-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Social Media
                            </h4>
                            <p>
                                Gestão de redes sociais criativa para <br /> engajar 
                                o público e fortalecer a presença <br /> digital.
                            </p>
                        </div>
                    </div>

                    <div className="col-4 mt-100"> 
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Inbound Marketing
                            </h4>
                            <p>
                                Técnicas de atração e engajamento para <br /> 
                                transformar visitantes em clientes fiéis.
                            </p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                ADS em todas as plataformas
                            </h4>
                            <p>
                                Anúncios pagos estrategicamente <br /> posicionados 
                                para aumentar a visibilidade <br /> da marca.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <Link to="#main-footer" className="button gradient">Aumentar as Vendas</Link>
                    </div>
                </div>
                
            </section>

            <section className="container-fluid" id="section2">
                <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 bg"></div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 box">
                        <h3>
                            Na <strong>SATO7</strong>, estamos <br /> ansiosos para sermos <br /> 
                            seu parceiro de <br /> confiança na jornada <br /> para o sucesso digital.
                        </h3>
                        <p>
                            Com nossa experiência, expertise e criatividade, estamos 
                            preparados para <br /> impulsionar sua marca para novas alturas, 
                            superando as expectativas e conquistando <br /> resultados extraordinários!
                        </p>
                    </div>

                </div>  

                 <div className="row">

                    <div className="col-12">
                        <h2>Parcerias e Certificações</h2>

                        <div className="container-partner">
                            <div className="grid-item">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item ml-80">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item ml-80">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item ml-80">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item ml-80">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                            <div className="grid-item ml-80">
                                <img src={process.env.PUBLIC_URL + "/images/pages/squadDigital/partner.svg"} alt="" />
                            </div>
                        </div>

                        <div className="carouselCustomerMobile">
                            <Swiper 
                                modules={[Pagination]} 
                                className="mySwiper"
                                freeMode={true}
                                centeredSlides={true}
                                slidesPerView={2}
                                loop={true}
                                watchSlidesProgress={true}
                                allowTouchMove={true}
                                pagination={pagination}
                            >
                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/images/customers/logo-corinthians.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-volkswagen.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-amanco.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-unilever.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-chevrolet.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-putzmeister.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-sany.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-oralsin.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-santoremedio.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="item">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/customers/logo-sumire.svg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                            
                        </div>

                    </div>

                </div>            
                
            </section>

        </div>
 
        <Footer />
    </>
  );
}

export default SquadDigital;
