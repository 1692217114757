import React, { useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/less/navigation";
import "swiper/css";

const CarouselPartners = () => {
	useEffect(() => { });

	return (
		<>
			<section className="container-fluid" id="section6">
				<div className="row">
					<div class="col-10 offset-1">
						<h3 className="text-uppercase">Certificações e Parcerias</h3>
						<Swiper
							modules={[Autoplay, Navigation, Pagination]}
							className="swiper carousel-partner-home"
							freeMode={true}
							breakpoints={{
								576: {
									slidesPerView: 3,
								},
								768: {
									slidesPerView: 5,
								},
								992: {
									slidesPerView: 7,
								},
							}}
							spaceBetween={30}
							loop={true}
							watchSlidesProgress={false}
							allowTouchMove={false}
							allowSlidePrev={true}
							slidesPerView={3} 
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							autoplay={{
							  delay: 2500,
							  disableOnInteraction: false,
							}}
							pagination={{
								clickable: true,
							}}
						>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-meta.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-tiktok.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-google.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-microsoft.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container opacity3">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-magento.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container opacity2">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-wake.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container opacity1">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-rd-station.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-meta.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-tiktok.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-google.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-microsoft.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-magento.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-wake.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partner-container">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/partners/partner-logo-rd-station.webp"
										}
										alt=""
									/>
								</div>
							</SwiperSlide>
						</Swiper>

						<div className="pagination">
							<div
								className="swiper-button-prev swiper-custom-nav"
							></div>
							<div
								className="swiper-button-next swiper-custom-nav"
							></div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default CarouselPartners;
