import React from "react";
import Footer from "../templates/Footer";

import "../assets/scss/PoliticaPrivacidade.scss";
import Main from "../templates/Main";

function PoliticaPrivacidade() {
	return (
		<>
			<Main />

			<div id="politica-privacidade">
				<div className="container">
					<div className="row">
						<div className="col-10 offset-1">
							<h3>Política de Privacidade</h3>
							<p>
								Esta política de privacidade tem como objetivo esclarecer como
								tratamos e protegemos as informações pessoais coletadas de
								nossos usuários. Ao utilizar nossos serviços, você concorda com
								a coleta e uso das informações conforme descrito nesta política.
							</p>

							<h3>Coleta de Informações</h3>

							<p>
								Ao utilizar nossos serviços, podemos solicitar que você nos
								forneça algumas informações pessoais identificáveis, que são
								consideradas dados sensíveis. As informações pessoais coletadas
								podem incluir:
							</p>
							<ul>
								<li>Nome</li>
								<li>Telefone</li>
								<li>E-mail</li>
								<li>Uso das Informações</li>
							</ul>
							<p>
								As informações coletadas serão usadas exclusivamente para entrar
								em contato com o cliente para o primeiro atendimento. Essas
								informações não serão utilizadas para outras finalidades além do
								contato inicial, a menos que o cliente forneça consentimento
								explícito para outros usos.
							</p>

							<h3>Proteção das Informações</h3>
							<p>
								Estamos comprometidos em proteger a privacidade e a segurança
								das suas informações pessoais. Utilizamos medidas de segurança
								adequadas para proteger suas informações contra acesso não
								autorizado, alteração, divulgação ou destruição.
							</p>

							<h3>Compartilhamento de Informações</h3>
							<p>
								As suas informações pessoais não serão compartilhadas com
								terceiros, exceto conforme exigido por lei ou com o seu
								consentimento explícito.
							</p>

							<h3>Alterações nesta Política de Privacidade</h3>
							<p>
								Podemos atualizar nossa política de privacidade periodicamente.
								Recomendamos que você revise esta página regularmente para se
								manter informado sobre como protegemos suas informações
								pessoais.
							</p>

							<h3>Contato</h3>
							<p>
								Se você tiver alguma dúvida sobre esta política de privacidade,
								entre em contato conosco através dos nossos
								canais de comunicação.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
export default PoliticaPrivacidade;
