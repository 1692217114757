import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import Footer from '../templates/Footer';

import '../assets/scss/SquadEcommerce.scss'

import Main from '../templates/Main';
import MainSocialVert from "../components/MainSocialVert";

function SquadEcommerce() {

  return (
    <>
        <Main /> 

        <div id="squadEcommerce">
 
            <header className="container-fluid">
                <div className="row">
                
                    <div className="col-2">
                        <MainSocialVert />
                    </div>
                        
                    <div className="col-10">
                        <div className="content">
                            <h2>Jornada de Alta <br /> Performance Digital <br /> para o Sucesso no <br /> <strong>E-commerce</strong>!</h2>
                            <Link to="#main-footer" className="first  button gradient">Impulsionar o crescimento</Link>                      
                        </div>
                    </div>
                </div>

            </header>  


            <section className="container-fluid" id="section1">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 bg"></div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                        <div className="content">
                            <h3>PORQUE A WAKE <br /> COMMERCE?</h3>
                            <p>
                                A Wake Commerce oferece uma plataforma modular, escalável e de fácil integração. <br />
                                Suas Vantagens incluem: Flexibilidade Superior, Menor TCO, Suporte Técnico <br />
                                Aprimorado, Atualizações Regulares, Experiência do Usuário, Segurança, Gestão de <br />
                                Vitrine mais completa do Mercado e um Ecossistema Completo de Integração com <br />
                                mais de 500 Ferramentas e Soluções Complementares.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="container-fluid" id="section2">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <h3>Soluções Digitais de <br /> Alta Performance para <br /><strong>E-commerce</strong></h3>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                        <div className="content">
                            <p>
                                 Unimos criatividade, inteligência e tecnologia para elevar a sua loja virtual. Com a<br />
                                 nossa plataforma principal, a WAKE do Grupo Locaweb, e uma equipe de especialistas<br />
                                 apaixonados pelo que fazem, estamos prontos para transformar a sua visão em<br /> 
                                 realidade.
                            </p>
                        </div>

                    </div>
                </div>


                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                Data Science
                            </h4>
                            <p>
                                Gorem ipsum dolor sit amet, consectetur <br /> adipiscing elit. Nunc vulputate libero et velit <br /> interdum, 
                                ac aliquet odio mattis. Class <br /> aptent taciti sociosqu ad litora torquent per <br /> conubia nostra, 
                                per inceptos himenaeos. <br /> Curabitur tempus urna at turpis <br /> condimentum lobortis.
                            </p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                ADS
                            </h4>
                            <p>
                                Gorem ipsum dolor sit amet, consectetur <br /> adipiscing elit. Nunc vulputate libero et velit <br /> interdum, 
                                ac aliquet odio mattis. Class <br /> aptent taciti sociosqu ad litora torquent per <br /> conubia nostra, 
                                per inceptos himenaeos. <br /> Curabitur tempus urna at turpis <br /> condimentum lobortis.
                            </p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="card">
                            <h4>
                                <i className="icon"></i> 
                                E-Commerce
                            </h4>
                            <p>
                                Gorem ipsum dolor sit amet, consectetur <br /> adipiscing elit. Nunc vulputate libero et velit <br /> interdum, 
                                ac aliquet odio mattis. Class <br /> aptent taciti sociosqu ad litora torquent per <br /> conubia nostra, 
                                per inceptos himenaeos. <br /> Curabitur tempus urna at turpis <br /> condimentum lobortis.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">


                    <div className="col-12">

                        <h2>Nossa Metodologia</h2>


                        <div className="timeline">
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline1.svg"} alt="" />
                                </i>
                                <p>Passo 1</p>
                                <h5>Briefing e Deep <br /> Dive</h5>
                            </div>
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline2.svg"} alt="" />
                                </i>
                                <p>Passo 2</p>
                                <h5>Research e <br /> Protótipo</h5>
                            </div>
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline3.svg"} alt="" />
                                </i>
                                <p>Passo 3</p>
                                <h5>Preview <br /> Interativo</h5>
                            </div>
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline4.svg"} alt="" />
                                </i>
                                <p>Passo 4</p>
                                <h5>Codificação <br /> Full Stack </h5>
                            </div>
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline5.svg"} alt="" />
                                </i>
                                <p>Passo 5</p>
                                <h5>Suporte e <br /> Configurações </h5>
                            </div>
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline6.svg"} alt="" />
                                </i>
                                <p>Passo 6</p>
                                <h5>QA <br /> Testes</h5>
                            </div>
                            <div className="item">
                                <i className="icon">
                                    <img src={process.env.PUBLIC_URL + "/images/pages/squadEcommerce/icon-timeline7.svg"} alt="" />
                                </i>
                                <p>Passo 7</p>
                                <h5>Pré Go-live e <br /> GO-LIVE! </h5>
                            </div>
                        </div>
                    </div>


                    
                </div>

            </section>


            <section className="container-fluid" id="section3">

                <div className="content-top">
                    <div className="row">
                        <div className="col-12">
                            <h2>Hey, você já possui um <br /> <strong>E-Commerce?</strong></h2>
                            <p>
                                Veja o que nossa equipe de Performance digital <br />
                                pode fazer para impulsionar os seus resultados
                            </p>
                        </div>
                    </div>

                    <div className="row content-middle">
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8"> 

                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <div className="card">
                                            <i className="icon"></i>
                                            <p>Tráfego para Redes Sociais</p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <div className="card">
                                            <i className="icon"></i>
                                            <p>Google Shopping e Google Ads</p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <div className="card">
                                            <i className="icon"></i>
                                            <p>Criação de Conteúdo Estratégico</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <div className="card">
                                            <i className="icon"></i>
                                            <p>Analista e Inteligência de Dados</p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <div className="card">
                                            <i className="icon"></i>
                                            <p>Inbound Marketing <br />  Personalizado</p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <div className="card">
                                            <i className="icon"></i>
                                            <p>Consultoria em Growth para <br /> Expansão Sustentável</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>



                <div className="row content-bottom">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <p>
                            Nossa paixão é ver o seu negócio prosperar online.<br />
                            Junte-se a nós na SATO7 e alcance novos horizontes<br /> 
                            no e-commerce!
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Link to="#main-footer">Cadastre-se agora e transforme o seu e-commerce!</Link>    
                    </div>
                </div>

            </section>


        </div>
 
        <Footer />
    </>
  );
}

export default SquadEcommerce;
