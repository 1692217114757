import React from "react";
import { Link } from "react-router-dom";

import "../assets/scss/MainSocialVert.scss";

const MainSocialVert = () => {
	return (
		<>
			<div className="socialVert">
				<Link
					to="https://www.instagram.com/agenciasato7/"
					className="icon icon-instagram"
					target="_blank"
				></Link>
				<Link
					to="https://www.facebook.com/Sato7/?locale=pt_BR"
					className="icon icon-facebook"
					target="_blank"
				></Link>
				<Link
					to="https://br.linkedin.com/company/sato7"
					className="icon icon-linkedin"
					target="_blank"
				></Link>
			</div>
		</>
	);
};

export default MainSocialVert;
