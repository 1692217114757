import React from "react";

const CarouselServices = () => {
	return (
		<>
			<section className="container" id="section3">
				<header>
					<h2 className="text-center">
						A <strong>SATO7</strong> É
					</h2>
				</header>
				<div className="sato-services-wrapper">
					<div className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>01</span>Growth Marketing
								</h3>
								<p>
									Em nossa agência, o Growth Marketing é mais do que uma
									estratégia de planejamento e ação, é uma promessa de
									crescimento exponencial que elaboramos junto ao cliente. Com
									uma equipe altamente especializada, com experiências em
									grandes players nacionais e internacionais, mergulhamos a
									fundo nos detalhes do seu público-alvo, identificamos
									oportunidades e executamos campanhas com mais precisão e
									assertividade. Nosso objetivo é aumentar suas conversões,
									impulsionar o seu tráfego e recriar um novo funil de
									resultados para superar suas expectativas. O crescimento é a
									nossa Métrica, e a sua satisfação e resultado é o nosso
									objetivo!
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-01-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
					<div className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>02</span>Data Driven & Analytics
								</h3>
								<p>
									A tomada de decisões com base em dados é a chave para o
									sucesso no marketing digital. Com a nossa expertise e equipe
									de analistas de dados dedicados em Data Driven e Analytics,
									transformamos dados brutos em insights estratégicos.
									Monitoramos, analisamos e traduzimos informações valiosas para
									guiar suas campanhas. Não é apenas coletar dados, mas saber
									trata-los para direcionar nossos esforços numa via de mão
									dupla com o cliente e ser mais eficiente, sempre com o
									objetivo em otimizar o ROI.
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-02-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
					<div className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>03</span>Business Intelligence
								</h3>
								<p>
									Na busca por resultados tangíveis, o BI é Crucial. Nossa
									equipe de BI se dedica a criar e fornecer tecnologia para os
									analistas de dados, tudo para que possamos ter uma visão ampla
									da tendência de onde estamos e para onde iremos. O objetivo
									deste time e auxiliar o time de Data Driven e Analytics para
									direcionar esforços de maneira eficiente.
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-03-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
					<div className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>04</span>Digital Performance
								</h3>
								<p>
									Nossa expertise em Digital Performance é o que impulsiona o
									sucesso das campanhas de nossos clientes, um setor
									especializado em franquias, com mais de 150 clientes ativos,
									atendendo grandes players como SMZTO, MAGRASS e SUMIRÊ. Não se
									trata apenas de presença online, mas sim de otimização diária
									com resultados tangíveis. Nossa equipe é altamente
									especializada neste setor e sabe como transformar cada clique
									em um passo em direção ao crescimento do seu negócio.
									Monitoramos de perto o desempenho de cada campanha e ajustamos
									estratégias em tempo real para garantir que você obtenha o
									melhor retorno do seu investimento.
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-04-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
					<div className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>05</span>Digital Trade Marketing
								</h3>
								<p>
									O Trade Marketing é um grande meio para alavancar e destacar
									os produtos em um mercado competitivo dentro do Retail ou loja
									on line. Estamos presentes desde 2019 na NRF BIG SHOW em NY e
									agora estamos presentes também na Web Summit. Com estes anos
									de conhecimentos e conceitos, conseguimos criar estratégias
									digitais que são meticulasamente planejadas, garantindo que a
									sua marca brilhe entre a concorrência no Retail Digital.
									Trabalhamos para criar experiências envolventes para o seu
									público, desenvolvendo estratégias de distribuição de
									informação eficaz e acreditamos que o Digital Trade Marketing
									é um dos maiores fatores de sucesso para vendas on-line e
									off-line.
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-05-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
					<div id="card02" className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>06</span>Branded Creative Content
								</h3>
								<p>
									A criatividade é a força motriz de toda e qualquer agência,
									não adianta apenas analisar dados se não souber o que criar
									para converter estes dados em resultados. Aqui na SATO7 temos
									uma equipe de conteúdo especialista em Branded Creative
									Content, composto desde diretores de criação, motion,
									copywriters e até mesmo uma equipe completa de captura e
									edição de vídeos, com um estúdio de gravação próprio. Uma
									equipe apaixonada por contar histórias de uma marca de maneira
									envolvente, aumentando o reconhecimento e o envolvimento do
									público.
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-06-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
					<div className="row sato-services-item">
						<div className="col-12 sato-services-body">
							<div className="sato-services-content">
								<h3>
									<span>07</span>E-Commerce
								</h3>
								<p>
									O mais novo setor da SATO7 nasceu com a grande missão de
									transformar o comércio eletrônico em uma máquina de vendas
									eficiente. Desde a otimização da experiência do usuário até a
									estratégias de retenção de clientes. O nosso foco está em
									impulsionar as vendas com a plataforma Wake da Locaweb, uma
									nova plataforma que possui integrações completas e inovadores
									que fomentam as vendas e maximiza o potencial de receita on
									line. Possuímos também um time dedicado para performar as
									vendas dentro de um e-commerce já existente, tudo com o
									objetivo em resultados!
								</p>
							</div>

							<video preload="auto" width="100%" height="100%" loop autoPlay muted>
								<source
									src={
										process.env.PUBLIC_URL +
										"/videos/home-video-timeline-07-sato7.mp4"
									}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default CarouselServices;
