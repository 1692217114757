import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

import Home from "./pages/Home";
import Agency from "./pages/Agency";

import Franquias from "./pages/Franquias";

import SquadDigital from "./pages/SquadDigital";
import SquadEcommerce from "./pages/SquadEcommerce";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-ultimate";

// import NoPage from "./Pages/NoPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<GoogleReCaptchaProvider
			type="v3"
			siteKey="6Lduvw0pAAAAAKDIeQHKZ4L_gnLLcnsNubqMB2WL"
			isEnterprise
		>
			<BrowserRouter>
				<Routes>
					<Route path="/" index element={<Home />} />
					<Route path="/agencia" element={<Agency />} />
					<Route path="/franquias" element={<Franquias />} />
					<Route path="/squad-digital" element={<SquadDigital />} />
					<Route path="/squad-ecommerce" element={<SquadEcommerce />} />
					<Route path="/politica-de-privacidade" element={<PoliticaPrivacidade />} />
				</Routes>
			</BrowserRouter>
		</GoogleReCaptchaProvider>
	</React.StrictMode>
);

reportWebVitals();
