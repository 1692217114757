import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

import "../assets/scss/Main.scss";

const tagManagerArgs = {
	gtmId: "GTM-NSP2CT3N",
};

const Main = () => {
	TagManager.initialize(tagManagerArgs);

	const [isActive, setMain] = useState(false);

	const btnMain = () => {
		setMain(!isActive);
	};

	return (
		<>
			<Helmet>
				<meta
					name="adopt-website-id"
					content="14058301-4390-437a-84f2-b1242c678322"
				/>
				<script
					src="//tag.goadopt.io/injector.js?website_code=14058301-4390-437a-84f2-b1242c678322"
					class="adopt-injector"
				></script>
			</Helmet>
			<section className="navbar">
				<Link to="/" className="logo"></Link>
				<div className="btn-main" onClick={btnMain}></div>
			</section>

			<section className={`container-main ${isActive ? "main-active" : ""}`}>
				<div className="h-full d-flex align-items-center">
					<div className="btn-main-close" onClick={btnMain}></div>

					<nav className="main-links">
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/agencia">Agência</Link>
							</li>
							<li>
								<Link to="/franquias">Franquias</Link>
							</li>
							<li>
								<Link to="/squad-digital">Performance</Link>
							</li>
							<li>
								<Link to="/squad-ecommerce">E-commerce</Link>
							</li>
							<li>
								<Link
									to="https://appsato7.com.br"
									className="button white"
									target="_blank"
								>
									Área do Cliente
								</Link>
							</li>
							<li>
								<Link
									to="/politica-de-privacidade"
									target="_blank"
								>
									Política de Privacidade
								</Link>
							</li>
						</ul>
					</nav>

					<div className="main-social">
						<Link
							to="https://www.instagram.com/agenciasato7/"
							target="_blank"
							className="icon icon-instagram"
						></Link>
						<Link
							to="https://www.facebook.com/Sato7/?locale=pt_BR"
							target="_blank"
							className="icon icon-facebook"
						></Link>
						<Link
							to="https://br.linkedin.com/company/sato7"
							target="_blank"
							className="icon icon-linkedin"
						></Link>
					</div>
				</div>
			</section>
		</>
	);
};

export default Main;
