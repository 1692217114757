import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import "../assets/scss/Footer.scss";

const Footer = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [company, setCompany] = useState("");
	const [position, setPosition] = useState("");
	const [segment, setSegment] = useState("");
	const [message, setMessage] = useState("");

	const [enviando, setEnviando] = useState(false);

	function sendEmail(e) {
		e.preventDefault();

		if (
			name === "" ||
			email === "" ||
			phone === "" ||
			company === "" ||
			position === "" ||
			segment === "" ||
			message === ""
		) {
			alert("Preencha todos os campos");
			return;
		}

		const templateParams = {
			from_name: name,
			email: email,
			phone: phone,
			company: company,
			position: position,
			segment: segment,
			message: message,
		};

		emailjs
			.send(
				"service_qixul16",
				"template_imvfnol",
				templateParams,
				"YM5N2uxakgXbS6uiF",
				setEnviando(true)
			)
			.then(
				(response) => {
					console.log("Email enviado", response.status, response.text);
					setName("");
					setEmail("");
					setPhone("");
					setCompany("");
					setPhone("");
					setPosition("");
					setSegment("");
					setMessage("");
					alert(
						"Seu contato foi recebido e um de nossos especialistas entrará em contato."
					);
					setEnviando(false);
				},
				(err) => {
					console.log("Erro:", err);
				}
			);
	}

	return (
		<>
			<footer id="main-footer" className="container-fluid">
				<video
					preload="auto"
					id="video"
					width="100%"
					height="100%"
					loop
					autoPlay
					muted
				>
					<source
						src={
							process.env.PUBLIC_URL + "/videos/home-header-video-bg-sato7.mp4"
						}
						type="video/mp4"
					/>
					Seu navegador não possui suporte para tags de vídeos. Por favor,
					utilize um navegador mais atualizado.
				</video>

				<div className="column-one">
					<div>
						<div className="logo"></div>
					</div>
				</div>
				<div className="column-two links">
					<h3>Navegue</h3>
					<ul>
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/agencia">Agência</Link>
						</li>
						<li>
							<Link to="/franquias">Franquias</Link>
						</li>
						<li>
							<Link to="/squad-digital">Performance</Link>
						</li>
						<li>
							<Link to="/squad-ecommerce">E-commerce</Link>
						</li>
						<li>
							<Link
								to="https://appsato7.com.br"
								target="_blank"
							>
								Área do Cliente
							</Link>
						</li>
						<li>
							<Link
								to="/politica-de-privacidade"
								target="_blank"
							>
								Política de Privacidade
							</Link>
						</li>
					</ul>
				</div>
				<div className="column-three contact">
					<h3>Contato</h3>
					<ul>
						<li>
							<i className="icon-phone"></i>
							<div>
								<h5>Telefone</h5>
								<p>(11) 2427-8879</p>
							</div>
						</li>
						<li>
							<i className="icon-email"></i>
							<div>
								<h5>E-mail</h5>
								<p>contato@sato7.com.br</p>
							</div>
						</li>
						<li>
							<i className="icon-location"></i>
							<div>
								<h5>Endereço</h5>
								<p>
									Av. Dona Gertrudes, 448, <br /> Alvinópolis - Atibaia/SP
								</p>
							</div>
						</li>
					</ul>
				</div>
				<div className="column-four">
					<h3>Fale Conosco</h3>
					<form
						id="forms"
						className="form"
						onSubmit={(sendEmail)}
					>
						<input
							type="text"
							className="form-control"
							placeholder="Nome"
							required
							onChange={(e) => setName(e.target.value)}
							value={name}
						/>
						<input
							type="email"
							className="form-control"
							placeholder="E-mail"
							required
							onChange={(e) => setEmail(e.target.value)}
							value={email}
						/>
						<input
							type="tel"
							className="form-control"
							placeholder="Telefone"
							required
							onChange={(e) => setPhone(e.target.value)}
							value={phone}
						/>
						<input
							type="text"
							className="form-control"
							placeholder="Empresa"
							required
							onChange={(e) => setCompany(e.target.value)}
							value={company}
						/>
						<input
							type="text"
							className="form-control"
							placeholder="Cargo"
							required
							onChange={(e) => setPosition(e.target.value)}
							value={position}
						/>
						<select
							className="form-control"
							placeholder="Segmento"
							required
							onChange={(e) => setSegment(e.target.value)}
							value={segment}>
								<option>Selecione um segmento de interesse</option>
								<option value="Franquia">Franquia</option>
								<option value="Performance">Performance</option>
								<option value="Conteúdo">Conteúdo</option>
								<option value="e-Commerce">E-Commerce</option>
						</select>
						<textarea
							name="message"
							className="form-control"
							placeholder="Descreva nosso desafio"
							id=""
							cols="30"
							rows="5"
							onChange={(e) => setMessage(e.target.value)}
							value={message}
						></textarea>
						<button type="submit" disabled={enviando}>
							{enviando ? "Enviando..." : "Enviar"}
						</button>
					</form>
				</div>
				<div className="column-five">
					<p className="copy">© Sato7 2023. Todos os Direitos Reservados</p>
					<div className="social">
						<Link
							to="https://www.instagram.com/agenciasato7/"
							className="icon icon-instagram"
							target="_blank"
						></Link>
						<Link
							to="https://www.facebook.com/Sato7/?locale=pt_BR"
							className="icon icon-facebook"
							target="_blank"
						></Link>
						<Link
							to="https://br.linkedin.com/company/sato7"
							className="icon icon-linkedin"
							target="_blank"
						></Link>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
