import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import emailjs from "@emailjs/browser";

import Footer from "../templates/Footer";

import "../assets/scss/Agency.scss";

import Main from "../templates/Main";
import MainSocialVert from "../components/MainSocialVert";

function Agency() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [position, setPosition] = useState("");
	const [portfolio, setPortfolio] = useState("");
	const [file, setFile] = useState("");
	const [message, setMessage] = useState("");

	const [enviando, setEnviando] = useState(false);

	function sendEmail(e) {
		e.preventDefault();

		if (
			name === "" ||
			email === "" ||
			phone === "" ||
			position === "" ||
			portfolio === "" ||
			file === "" ||
			message === ""
		) {
			alert("Preencha todos os campos");
			return;
		}

		const templateParams = {
			from_name: name,
			email: email,
			phone: phone,
			position: position,
			portfolio: portfolio,
			file: file,
			message: message,
		};

		emailjs
			.send(
				"service_qixul16",
				"template_697rgro",
				templateParams,
				"YM5N2uxakgXbS6uiF",
				setEnviando(true)
			)
			.then(
				(response) => {
					console.log("Email enviado", response.status, response.text);
					setName("");
					setEmail("");
					setPhone("");
					setPosition("");
					setPortfolio("");
					setFile("");
					setMessage("");
					alert(
						"Seu contato foi recebido e um de nossos especialistas entrará em contato."
					);
					setEnviando(false);
				},
				(err) => {
					console.log("Erro:", err);
				}
			);
	}

	return (
		<>
			<Main />

			<div id="agency">
				<header className="container-fluid">
					<div className="row">
						<div className="col-2 d-none d-sm-none d-md-block">
							<MainSocialVert />
						</div>

						<div className="col-10">
							<div className="content">
								<h1>
									<strong>Agência</strong>
								</h1>
								<p className="highlight-text">
									Shoiti Sato, fundador da Sato 7, está conectado com
									computadores desde 1987.
								</p>
								<p>
									Esse sempre foi seu hobby mais divertido. Sempre com seu irmão
									ao lado, foi ainda de sua casa que criou um sistema de
									compartilhamento de dados, em 1991.
								</p>
								<p>
									Para a época, baixar dados, letras de músicas e imagem era
									algo que levava dias, por isso, os dois meninos
									desenvolveram um sistema próprio para compartilhar com
									pessoas de todos os mundos.
								</p>
							</div>

						</div>
					</div>
				</header>

				<section className="container-fluid" id="section2">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
							<h2>
								Junte-se à <br /> nossa equipe!
							</h2>

							<p className="text">
								Aqui, buscamos talentos que desejam <br /> fazer a diferença em
								um ambiente <br /> colaborativo e inovador.
							</p>

							<p>
								Envie seu currículo e participe do nosso processo seletivo.{" "}
								<br /> Oferecemos oportunidades de crescimento profissional,{" "}
								<br />
								valorizamos a criatividade e encorajamos ideias inovadoras.{" "}
								<br /> Trabalhar conosco é fazer parte de uma família
								comprometida com <br /> o sucesso mútuo. Venha construir uma
								carreira promissora e <br /> inspiradora! Seja parte do nosso
								time e alcance novos patamares <br /> profissionais. Esperamos
								por você!
							</p>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
							<form id="job-forms" className="form" onSubmit={sendEmail}>
								<input
									type="text"
									className="form-control"
									name="name"
									placeholder="Nome"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
								/>
								<input
									type="email"
									className="form-control"
									name="email"
									placeholder="E-mail"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={email}
								/>
								<input
									type="tel"
									className="form-control"
									name="phone"
									placeholder="Telefone com DDD"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={phone}
								/>
								<input
									type="text"
									className="form-control"
									name="office"
									placeholder="Cargo pretendido"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={position}
								/>
								<input
									type="text"
									className="form-control"
									name="company"
									placeholder="Link do portfólio"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={portfolio}
								/>
								<input type="file" className="form-control" name="attachment" />
								<textarea
									name="message"
									className="form-control"
									placeholder="Outras informações relevantes"
									id=""
									cols="30"
									rows="5"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={message}
								></textarea>
                                <button type="submit" disabled={enviando}>
                                    {enviando ? "Enviando..." : "Enviar"}
                                </button>
							</form>

							<video
								preload="auto"
								id="video"
								width="100%"
								height="100%"
								loop
								autoPlay
								muted
							>
								<source
									src={process.env.PUBLIC_URL + "/videos/video-agency.mp4"}
									type="video/mp4"
								/>
								Seu navegador não possui suporte para tags de vídeos. Por favor,
								utilize um navegador mais atualizado.
							</video>
						</div>
					</div>
				</section>

				<section className="container-fluid" id="section4">
					<div className="row">
						<div className="col-12">
							<div className="box">
								<h2>Você deseja os nossos serviços?</h2>
								<p>
									Transforme sua visão em realidade. Descubra como nossos
									serviços podem <br />
									impulsionar o sucesso do seu negócio.
								</p>
								<Link to="#main-footer">Entre em contato conosco</Link>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	);
}

export default Agency;
